import { ColumnDef } from '@tanstack/react-table';
import { ArrowUpDown } from 'lucide-react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useTheme from 'chat-ui/src/hooks/useTheme';
import { IconKeyboard, IconVolume } from '@tabler/icons-react';
import { Button } from '@/components/ui/button';
import formatDate from '@/utils/dateFormat';
import UserFilledIcon from '@/components/icons/UserFilledIcon';
import calculateConversationDuration from '@/utils/calculateConversationDuration';
import TooltipWrapper from '@/components/tooltip/TooltipWrapper';

// eslint-disable-next-line import/prefer-default-export
export const TranscriptsColumns = (metadataKeysArray: string[]) => {
  const navigate = useNavigate();
  const { currentTheme } = useTheme();
  const [searchParams] = useSearchParams();
  const filter_id = searchParams.get('filter_id');
  const question_id = searchParams.get('ai_search_question');
  const search_page = searchParams.get('ai_search_question_page');

  const setParams = (conversation_id: string) => {
    const params: { [key: string]: string | null } = {
      filter_id,
      ai_search_question: question_id,
      ai_search_question_page: search_page,
      selected_transcript: conversation_id,
    };

    // Filter out null values
    const filteredParams = Object.entries(params)
      .filter(([, value]) => value !== null)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    return `?${filteredParams}`;
  };

  const columns: ColumnDef<AllTranscriptsProps>[] = [
    {
      accessorKey: '_id',
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="pl-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Participant
          <ArrowUpDown className="w-4 h-4 ml-2" />
        </Button>
      ),
      cell: ({ row }) => (
        <div className="flex items-center gap-3 whitespace-nowrap">
          <button
            type="button"
            className="flex items-center gap-4 font-semibold transition-all ease-in-out hover:underline"
            onClick={() => navigate(setParams(row && String(row.index + 1)))}
          >
            <div
              style={{ color: currentTheme?.primary }}
            >
              <UserFilledIcon className="size-7" />
            </div>
            <p>
              Participant
              {' '}
              {row && row.index + 1}
            </p>
            <div className="flex items-center gap-1.5 text-black/80">
              {row?.original?.messages?.some((item) => item?.text !== '' && item?.role === 'user') && <IconKeyboard size={24} />}
              {row?.original?.messages.some((message) => message.data?.some(
                (dataItem: File) => dataItem.type === 'audio',
              ) && message.role === 'user') && <IconVolume size={24} />}
            </div>
          </button>
        </div>
      ),
    },
    {
      accessorKey: 'createdAt',
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="pl-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Start time
          <ArrowUpDown className="w-4 h-4 ml-2" />
        </Button>
      ),
      cell: ({ row }) => (
        <div className="whitespace-nowrap">
          <p>
            {formatDate(row?.getValue('createdAt'), 'DD/MMM/YYYY - hh:mm:ss A')}
          </p>
        </div>
      ),
    },
    {
      id: 'duration',
      header: () => (
        <Button
          variant="ghost"
          className="pl-0"
        >
          Duration
        </Button>
      ),
      cell: ({ row }) => (
        <div className="whitespace-nowrap">
          <p>
            {calculateConversationDuration({ createdAt: row?.original?.createdAt, updatedAt: row?.original?.updatedAt })}
          </p>
        </div>
      ),
    },
    {
      accessorKey: 'summary',
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="pl-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Summary
          <ArrowUpDown className="w-4 h-4 ml-2" />
        </Button>
      ),
      cell: ({ row }) => (
        <TooltipWrapper text={row?.original?.digest?.summary || '/'} className="max-w-[700px] text-xs z-[9999999]">
          <p className="w-[500px] truncate">
            {row?.original?.digest?.summary || '/'}
          </p>
        </TooltipWrapper>
      ),
    },
  ];

  if (metadataKeysArray.length) {
    metadataKeysArray.forEach((key: string) => {
      columns.push({
        accessorKey: `metadata.${key}`,
        header: ({ column }) => (
          <Button
            variant="ghost"
            className="pl-0 capitalize"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            {key}
            <ArrowUpDown className="w-4 h-4 ml-2" />
          </Button>
        ),
        cell: ({ row }) => (
          <p className="whitespace-nowrap">{(row.original.metadata?.[key] || '/')}</p>
        ),
      });
    });
  }

  return columns;
};
