import { IconLoader2, IconChevronDown, IconCheck } from '@tabler/icons-react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useQuery, InvalidateQueryFilters, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState, Fragment } from 'react';
import useTheme from 'chat-ui/src/hooks/useTheme';
import { AxiosError } from 'axios';
import { Listbox, Transition } from '@headlessui/react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import useTranslation from '@/hooks/useTranslation';
import Overview from '../Content/Overview';
import Questions from '../Content/Questions';
import useResultTabStore from '@/store/ResultsTabStore';
import { Button } from '@/components/ui/button';
import { useMutateReRunAnalyze } from '@/reactQuery/post';
import { toast } from '@/components/ui/use-toast';
import ResultsFiltersModal from '@/components/Modals/ResultsFiltersModal';
import AISearch from '../Content/AISearch';
import axiosInstanceAnalyze from '@/axios/AxiosInstanceAnalyze';
import axiosInstance from '@/axios/axiosInstance';
import { EProjectStatus, EStatusCode } from '@/enums';
import ProjectReport from './ProjectReport';
import { cn } from '@/lib/utils';
import useReportStore from '@/store/ReportStore';
import useErrorStore from '@/store/ErrorStore';
import UserIcon from '@/components/icons/UserIcon';
import Squares2X2Icon from '@/components/icons/Squares2X2Icon';
import QuestionMarkCircleIcon from '@/components/icons/QuestionMarkCircleIcon';
import SparklesIcon from '@/components/icons/SparklesIcon';
import NewspaperIcon from '@/components/icons/NewspaperIcon';
import PaperAirplaneIcon from '@/components/icons/PaperAirplaneIcon';
import ConversationsTranscripts from '../Content/ConversationsTranscripts';

function ProjectResults() {
  const { lang } = useTranslation();
  const activeTab = useResultTabStore((state) => state.activeTab);
  const setActiveTab = useResultTabStore((state) => state.setActiveTab);
  const { project_id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const filter_id = searchParams.get('filter_id');
  const question_id = searchParams.get('ai_search_question');
  const search_page = searchParams.get('ai_search_question_page');
  const conv_id = searchParams.get('selected_transcript');
  const [selectedFilter, setSelectedFilter] = useState(filter_id || '');
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const clearReportData = useReportStore((state) => state.clearReportData);
  const { currentTheme } = useTheme();
  const setShowError = useErrorStore((state) => state.setShowError);
  const [openFilterModal, setOpenFilterModal] = useState(false);

  const { mutateAsync: mutateAsyncReRun, isPending: isPendingReRun } = useMutateReRunAnalyze();

  const { data: filtersListData } = useQuery({
    queryFn: () => axiosInstanceAnalyze.get(`/analyzer/filters/${project_id}/get`),
    queryKey: ['all_project_filters'],
    select: (data) => data?.data,
  });

  const { data: singleProjectData, error: projectDataError } = useQuery({
    queryFn: () => axiosInstance.get(`projects/get/${project_id}`),
    queryKey: ['single_project_data', { project_id }],
    select: (data) => data?.data,
  });

  const { data: newConversationsCount } = useQuery({
    queryFn: () => axiosInstanceAnalyze.get(`/analyzer/run/${project_id}/indicator${filter_id ? `?filter_id=${filter_id}` : ''}`),
    queryKey: ['new_conversations_count', { filter_id, project_id }],
    select: (data) => data?.data,
    refetchInterval: 30000,
  });

  const onReRun = async () => {
    try {
      await mutateAsyncReRun({ project_id });
      toast({ description: 'Re-run is done!' });
    } catch {
      toast({ description: lang.get('msg.errorPleaseTryAgain'), variant: 'destructive' });
    }
  };

  const setParams = (filter: string) => {
    const params: { [key: string]: string | null } = {
      ai_search_question: question_id,
      selected_transcript: conv_id,
      ai_search_question_page: search_page,
      filter_id: filter,
    };

    // Filter out null values
    const filteredParams = Object.entries(params)
      .filter(([, value]) => value !== null)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    return `?${filteredParams}`;
  };

  const removeSelectedTranscript = () => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.delete('selected_transcript');
    setSearchParams(newSearchParams);
  };

  const onFilterChange = (id: string) => {
    setSelectedFilter(id);
    navigate(setParams(id));
    removeSelectedTranscript();
    queryClient.invalidateQueries(['overall_data'] as InvalidateQueryFilters);
    queryClient.invalidateQueries(['single_conversations_data'] as InvalidateQueryFilters);
    queryClient.invalidateQueries(['question_insights'] as InvalidateQueryFilters);
    queryClient.invalidateQueries(['question_transcripts'] as InvalidateQueryFilters);
  };

  const getFilterNameById = (id: string) => filtersListData?.filters?.find((filter: { _id: string }) => filter._id === id)?.name || '';

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const analysisLanguage = singleProjectData?.analysis_language;

  const showFilters = singleProjectData?.status === EProjectStatus.COMPLETED;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => { clearReportData(); setActiveTab('overview_tab'); }, []);

  useEffect(() => {
    if ((projectDataError as AxiosError)?.status === EStatusCode.BAD_REQUEST) {
      setShowError(true);
    }
  }, [projectDataError, setShowError]);

  return (
    <main
      style={{ backgroundColor: currentTheme?.background, color: currentTheme?.['primary-text'] }}
      className="flex flex-col w-full h-full"
    >
      {/* tabs */}
      <Tabs defaultValue="overview_tab" className="w-full" value={activeTab} onValueChange={(value) => [setActiveTab(value), scrollToTop()]}>
        <div
          style={{ backgroundColor: currentTheme?.background, color: currentTheme?.['primary-text'] }}
          className="fixed flex items-center justify-between w-full py-2 -mt-[15px] border-b z-50"
        >
          <TabsList
            style={{ backgroundColor: currentTheme?.background, color: currentTheme?.['primary-text'] }}
            className="relative flex items-center justify-start w-full h-fit max1200:flex-wrap max1200:gap-5"
          >
            <TabsTrigger
              style={{ backgroundColor: currentTheme?.background, color: currentTheme?.['primary-text'] }}
              value="overview_tab"
              className="!shadow-none transition-all ease-in-out duration-300 w-52 max1200:w-40 font-bold text-base relative"
            >
              <div className="flex items-center gap-1.5">
                <Squares2X2Icon
                  style={{ color: currentTheme?.primary }}
                  className="size-5"
                />
                {lang.get('msg.overview')}
              </div>
              <hr
                style={{ backgroundColor: currentTheme?.primary }}
                className={`absolute -bottom-3.5 w-full transition-all ease-in-out duration-300 h-1 ${activeTab === 'overview_tab' ? 'opacity-100' : 'opacity-0'
                }`}
              />
            </TabsTrigger>
            <TabsTrigger
              value="questions_tab"
              style={{ backgroundColor: currentTheme?.background, color: currentTheme?.['primary-text'] }}
              className="!shadow-none transition-all ease-in-out duration-300 w-52 max1200:w-40 font-bold text-base relative "
            >
              <div className="flex items-center gap-1.5">
                <QuestionMarkCircleIcon
                  style={{ color: currentTheme?.primary }}
                  className="size-5"
                />
                {lang.get('msg.questions')}
              </div>
              <hr
                style={{ backgroundColor: currentTheme?.primary }}
                className={`absolute -bottom-3.5 w-full transition-all ease-in-out duration-300 h-1 ${activeTab === 'questions_tab' ? 'opacity-100' : 'opacity-0'
                }`}
              />
            </TabsTrigger>
            <TabsTrigger
              value="transcripts_tab"
              style={{ backgroundColor: currentTheme?.background, color: currentTheme?.['primary-text'] }}
              className="!shadow-none transition-all ease-in-out duration-300 w-52 max1200:w-40 font-bold text-base relative "
            >
              <div className="flex items-center gap-1.5">
                <UserIcon
                  style={{ color: currentTheme?.primary }}
                  className="size-5"
                />
                {lang.get('msg.transcripts')}
              </div>
              <hr
                style={{ backgroundColor: currentTheme?.primary }}
                className={`absolute -bottom-3.5 w-full transition-all ease-in-out duration-300 h-1 ${activeTab === 'transcripts_tab' ? 'opacity-100' : 'opacity-0'
                }`}
              />
            </TabsTrigger>
            <TabsTrigger
              value="ai_search_tab"
              style={{ backgroundColor: currentTheme?.background, color: currentTheme?.['primary-text'] }}
              className="!shadow-none transition-all ease-in-out duration-300 w-52 max1200:w-40 font-bold text-base relative "
            >
              <div className="flex items-center gap-1.5">
                <SparklesIcon
                  style={{ color: currentTheme?.primary }}
                  className="size-5"
                />
                {lang.get('msg.aiSearch')}
              </div>
              <hr
                style={{ backgroundColor: currentTheme?.primary }}
                className={`absolute -bottom-3.5 w-full transition-all ease-in-out duration-300 h-1 ${activeTab === 'ai_search_tab' ? 'opacity-100' : 'opacity-0'
                }`}
              />
            </TabsTrigger>
            <TabsTrigger
              value="report_tab"
              style={{ backgroundColor: currentTheme?.background, color: currentTheme?.['primary-text'] }}
              className="!shadow-none transition-all ease-in-out duration-300 w-52 max1200:w-40 font-bold text-base relative "
            >
              <div className="flex items-center gap-1.5">
                <NewspaperIcon
                  style={{ color: currentTheme?.primary }}
                  className=" size-5"
                />
                {lang.get('msg.report')}
              </div>
              <hr
                style={{ backgroundColor: currentTheme?.primary }}
                className={`absolute -bottom-3.5 w-full transition-all ease-in-out duration-300 h-1 ${activeTab === 'report_tab' ? 'opacity-100' : 'opacity-0'
                }`}
              />
            </TabsTrigger>
          </TabsList>

          {singleProjectData?.status !== EProjectStatus.COMPLETED && (
            <Button
              variant="default"
              disabled={isPendingReRun}
              className="relative flex items-center gap-2 mr-10 font-bold px-7"
              onClick={onReRun}
            >
              {
                isPendingReRun
                  ? <IconLoader2 className="animate-spin" size={20} />
                  : <PaperAirplaneIcon className="size-4" />
              }
              Re-run
              {newConversationsCount > 0 && (
                <span className="absolute flex items-center justify-center h-5 px-1.5 text-xs text-white bg-red-600 rounded-full w-fit -top-1 -right-1.5">
                  {newConversationsCount}
                </span>
              )}
            </Button>
          )}
        </div>

        {/* filters */}
        {showFilters && (
          <div
            style={{ borderColor: currentTheme?.['primary-text'] }}
            className="flex items-center justify-start w-full max-w-[1300px] mx-auto h-16 gap-3 mt-12 border-b md900:mt-24 md600:mt-40 border-light-gray/20"
          >
            {/* add filter */}
            <ResultsFiltersModal
              openFilterModal={openFilterModal}
              setOpenFilterModal={setOpenFilterModal}
              project_id={project_id}
              onFilterChange={onFilterChange}
            />

            {/* select filter */}
            <Listbox value={selectedFilter || ''} onChange={onFilterChange}>
              <div className="relative mt-1">
                <Listbox.Button className="flex relative truncate cursor-pointer w-52 h-8 items-center justify-between rounded-md border-2 shadow
                   border-neutral-200 bg-gray-50 text-black font-medium px-3 py-2 text-sm ring-offset-white placeholder:text-neutral-500
                    focus:outline-none focus:ring-1 disabled:cursor-not-allowed
                     disabled:opacity-50 [&>span]:line-clamp-1 dark:border-neutral-800 dark:bg-neutral-950
                       dark:placeholder:text-neutral-400 dark:focus:ring-neutral-300 ring-transparent appearance-none"
                >
                  <span className="w-40 truncate">{getFilterNameById(selectedFilter) || 'Select filter'}</span>
                  <span className="absolute right-0 flex items-center pr-2 -translate-y-1/2 pointer-events-none top-1/2">
                    <IconChevronDown
                      className="w-5 h-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-50 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black/5 focus:outline-none sm:text-sm">
                    <Listbox.Option
                      className={({ active }) => `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                      }`}
                      value=""
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                            }`}
                          >
                            {selected ? 'Select filter' : 'Remove filter'}
                          </span>
                          {selected ? (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                              <IconCheck className="w-5 h-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                    {filtersListData?.filters?.map((filter: { _id: number, name: string }) => (
                      <Listbox.Option
                        key={filter._id}
                        className={({ active }) => `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                        }`}
                        value={filter._id}
                      >
                        {({ selected }) => (
                          <>
                            <span
                              className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                              }`}
                            >
                              {filter.name}
                            </span>
                            {selected ? (
                              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                <IconCheck className="w-5 h-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </div>
        )}

        <TabsContent value="overview_tab" className={cn(showFilters ? 'pt-5' : 'pt-20 max1050:pt-28 min505:pt-72 md700:pt-44', 'pb-10 !ring-transparent !ring-0')}>
          <Overview
            isPendingReRun={isPendingReRun}
            analysisLanguage={analysisLanguage}
            singleProjectData={singleProjectData}
            newConversationsCount={newConversationsCount}
            onReRun={onReRun}
          />
        </TabsContent>
        <TabsContent value="transcripts_tab" className={cn(showFilters ? 'pt-1' : 'pt-14 max1050:pt-28 min505:pt-72 md700:pt-44', 'pb-10 !ring-transparent !ring-0')}>

          {/* <TranscriptsConversations /> */}
          <ConversationsTranscripts />

        </TabsContent>
        <TabsContent value="questions_tab" className={cn(showFilters ? 'pt-5' : 'pt-20 max1050:pt-28 min505:pt-72 md700:pt-44', 'pb-10 !ring-transparent !ring-0')}>
          <Questions
            analysisLanguage={analysisLanguage}
            isPendingReRun={isPendingReRun}
            singleProjectData={singleProjectData}
            newConversationsCount={newConversationsCount}
            onReRun={onReRun}
          />
        </TabsContent>
        <TabsContent value="ai_search_tab" className={cn(showFilters ? 'pt-5' : 'pt-20 max1050:pt-28 min505:pt-72 md700:pt-44', 'pb-10 !ring-transparent !ring-0')}>
          <AISearch />
        </TabsContent>
        <TabsContent value="report_tab" className={cn(showFilters ? 'pt-5' : 'pt-20 max1050:pt-28 min505:pt-72 md700:pt-44', 'pb-10 !ring-transparent !ring-0')}>
          <ProjectReport isPendingReRun={isPendingReRun} analysisLanguage={analysisLanguage} />
        </TabsContent>
      </Tabs>
    </main>
  );
}

export default ProjectResults;
