const getUniqueMetadataKeys = (allConversations: AllTranscriptsProps[]): Set<string> => {
  const metadataKeys = new Set<string>();

  allConversations.forEach((item) => {
    if (item.metadata) {
      Object.keys(item.metadata).forEach((key) => metadataKeys.add(key));
    }
  });

  return metadataKeys;
};

export default getUniqueMetadataKeys;
