import { useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import useTheme from 'chat-ui/src/hooks/useTheme';
import ReactMarkdown from 'react-markdown';
import { IconLoader2 } from '@tabler/icons-react';
import DataWithSteps from '@/components/QuestionItem/DataWithSteps';
import Statistics from './overview_content/Statistics';
import InterestingQuotes from '@/components/QuestionItem/InterestingQuotes';
import axiosInstanceAnalyze from '@/axios/AxiosInstanceAnalyze';
import SummarySkeletonLoader from '@/components/loaders/SummarySkeletonLoader';
import useReportStore from '@/store/ReportStore';
import { cn } from '@/lib/utils';
import FullPageLoader from '@/components/atoms/Loader/FullPageLoader';
import {
  translateAddToReport, translateInterestingQuotes, translateKeyThemes,
  translateNoKeyThemesData,
  translateNoOpportunitiesData,
  translateNoQuotesData,
  translateNoSummaryData,
  translateOpportunities, translateSummary,
} from './project_results/resultsPageTranslations';
import { Button } from '@/components/ui/button';
import PaperAirplaneIcon from '@/components/icons/PaperAirplaneIcon';
import { EProjectStatus } from '@/enums';

interface IOverall {
  isPendingReRun: boolean;
  analysisLanguage: string;
  singleProjectData: IProjectProps;
  newConversationsCount: number;
  onReRun: () => Promise<void>;
}

function Overview({ isPendingReRun, analysisLanguage, singleProjectData, newConversationsCount, onReRun }: IOverall) {
  const { project_id } = useParams();
  const [searchParams] = useSearchParams();
  const filter_id = searchParams.get('filter_id');
  const { currentTheme } = useTheme();

  const { data: overviewData, isLoading: isOverviewLoading } = useQuery({
    queryFn: () => axiosInstanceAnalyze.get(`/analyzer/results/${project_id}/overall${filter_id ? `?filter_id=${filter_id}` : ''}`),
    queryKey: ['overall_data', { filter_id }],
    select: (data) => data?.data,
  });

  const isSummaryChecked = useReportStore((state) => state.isSummaryChecked);
  const setIsSummaryChecked = useReportStore((state) => state.setIsSummaryChecked);
  const isKeyThemesChecked = useReportStore((state) => state.isKeyThemesChecked);
  const setIsKeyThemesChecked = useReportStore((state) => state.setIsKeyThemesChecked);
  const isQuotesChecked = useReportStore((state) => state.isQuotesChecked);
  const setIsQuotesChecked = useReportStore((state) => state.setIsQuotesChecked);
  const isOpportunitiesChecked = useReportStore((state) => state.isOpportunitiesChecked);
  const setIsOpportunitiesChecked = useReportStore((state) => state.setIsOpportunitiesChecked);
  const reportData = useReportStore((state) => state.reportData);
  const setReportData = useReportStore((state) => state.setReportData);

  const onReportSaveSummary = () => {
    setIsSummaryChecked(!isSummaryChecked);
    if (!isSummaryChecked) {
      setReportData({ ...reportData, summary: overviewData?.summary });
    } else {
      const { summary, ...restData } = reportData;
      setReportData(restData);
    }
  };

  const onReportSaveKeyThemes = () => {
    setIsKeyThemesChecked(!isKeyThemesChecked);
    if (!isKeyThemesChecked) {
      setReportData({ ...reportData, themes: overviewData?.themes });
    } else {
      const { themes, ...restData } = reportData;
      setReportData(restData);
    }
  };

  const onReportSaveQuotes = () => {
    setIsQuotesChecked(!isQuotesChecked);
    if (!isQuotesChecked) {
      setReportData({ ...reportData, quotes: overviewData?.quotes });
    } else {
      const { quotes, ...restData } = reportData;
      setReportData(restData);
    }
  };

  const onReportSaveOpportunities = () => {
    setIsOpportunitiesChecked(!isOpportunitiesChecked);
    if (!isOpportunitiesChecked) {
      setReportData({ ...reportData, opportunities: overviewData?.opportunities });
    } else {
      const { opportunities, ...restData } = reportData;
      setReportData(restData);
    }
  };

  const noEnoughInterviewData = overviewData?.statistics === null
    && overviewData?.summary?.length === 0 && overviewData?.themes?.length === 0
    && overviewData?.quotes?.length === 0
    && overviewData?.opportunities?.length === 0;

  if (isOverviewLoading) return <FullPageLoader />;

  return (
    !noEnoughInterviewData ? (
      <div
        style={{ backgroundColor: currentTheme?.background }}
        className="flex flex-col items-center justify-center w-full gap-16"
      >
        <Statistics showSelect data={overviewData?.statistics} isLoading={isPendingReRun || isOverviewLoading} project_id={project_id} />
        {/* summary */}
        <div className="flex flex-col items-center justify-center w-full">
          <div className="flex items-start justify-between w-full max-w-[1300px]">
            <h3
              style={{ color: currentTheme?.primary }}
              className="mb-6 font-semibold"
            >
              {translateSummary[analysisLanguage] || translateSummary.English}
            </h3>
            {overviewData?.summary?.length !== 0 && (!isPendingReRun && !isOverviewLoading) && (
            <div className="flex items-center gap-3">
              <p className="font-medium">{translateAddToReport[analysisLanguage] || translateAddToReport.English}</p>
              <div className="flex items-center justify-center w-5 h-5 border p-0.5 border-black rounded-full">
                <button
                  onClick={() => {
                    onReportSaveSummary();
                  }}
                  style={{ backgroundColor: isSummaryChecked ? currentTheme?.primary : '#FFFFFF' }}
                  type="button"
                  className={cn('w-full h-full rounded-full')}
                >
                </button>
              </div>
            </div>
            )}
          </div>
          {overviewData?.summary?.length !== 0 ? (
            <div className="max-w-[1300px] w-full">
              {isPendingReRun || isOverviewLoading
                ? <SummarySkeletonLoader />
                : <ReactMarkdown>{overviewData?.summary}</ReactMarkdown>}
            </div>
          ) : <p className="w-full text-left text-[15px] max-w-[1300px] mx-auto">{translateNoSummaryData[analysisLanguage] || translateNoSummaryData.English}</p>}
        </div>
        {/* key themes */}
        <div className="flex flex-col items-center justify-center w-full">
          <div className="flex items-start justify-between w-full max-w-[1300px]">
            <h3
              style={{ color: currentTheme?.primary }}
              className="mb-6 font-semibold"
            >
              {translateKeyThemes[analysisLanguage] || translateKeyThemes.English}
            </h3>
            {overviewData?.themes?.length !== 0 && (!isPendingReRun && !isOverviewLoading) && (
            <div className="flex items-center gap-3">
              <p className="font-medium">{translateAddToReport[analysisLanguage] || translateAddToReport.English}</p>
              <div className="flex items-center justify-center w-5 h-5 border p-0.5 border-black rounded-full">
                <button
                  onClick={() => {
                    onReportSaveKeyThemes();
                  }}
                  style={{ backgroundColor: isKeyThemesChecked ? currentTheme?.primary : '#FFFFFF' }}
                  type="button"
                  className={cn('w-full h-full rounded-full')}
                >
                </button>
              </div>
            </div>
            )}
          </div>
          {overviewData?.themes?.length !== 0
            ? <DataWithSteps data={overviewData?.themes} isLoading={isPendingReRun || isOverviewLoading} />
            : <p className="w-full text-left text-[15px] max-w-[1300px] mx-auto">{translateNoKeyThemesData[analysisLanguage] || translateNoKeyThemesData.English}</p>}
        </div>
        {/* quotes */}
        <div className="flex flex-col items-center justify-center w-full">
          <div className="flex items-start justify-between w-full max-w-[1300px]">
            <h3
              style={{ color: currentTheme?.primary }}
              className="mb-6 font-semibold"
            >
              {translateInterestingQuotes[analysisLanguage] || translateInterestingQuotes.English}
            </h3>
            {overviewData?.quotes?.length !== 0 && (!isPendingReRun && !isOverviewLoading) && (
            <div className="flex items-center gap-3">
              <p className="font-medium">{translateAddToReport[analysisLanguage] || translateAddToReport.English}</p>
              <div className="flex items-center justify-center w-5 h-5 border p-0.5 border-black rounded-full">
                <button
                  onClick={() => {
                    onReportSaveQuotes();
                  }}
                  style={{ backgroundColor: isQuotesChecked ? currentTheme?.primary : '#FFFFFF' }}
                  type="button"
                  className={cn('w-full h-full rounded-full')}
                >
                </button>
              </div>
            </div>
            )}
          </div>
          <div className="flex items-center justify-center w-full">
            {overviewData?.quotes?.length !== 0
              ? <InterestingQuotes analysisLanguage={analysisLanguage} data={overviewData?.quotes} isLoading={isPendingReRun || isOverviewLoading} />
              : <p className="w-full text-left text-[15px] max-w-[1300px] mx-auto">{translateNoQuotesData[analysisLanguage] || translateNoQuotesData.English}</p>}
          </div>
        </div>

        {/* opportunities */}
        <div className="flex flex-col items-center justify-center w-full">
          <div className="flex items-start justify-between w-full max-w-[1300px]">
            <h3
              style={{ color: currentTheme?.primary }}
              className="mb-6 font-semibold"
            >
              {translateOpportunities[analysisLanguage] || translateOpportunities.English}
            </h3>
            {overviewData?.opportunities?.length !== 0 && (!isPendingReRun && !isOverviewLoading) && (
            <div className="flex items-center gap-3">
              <p className="font-medium">{translateAddToReport[analysisLanguage] || translateAddToReport.English}</p>
              <div className="flex items-center justify-center w-5 h-5 border p-0.5 border-black rounded-full">
                <button
                  onClick={() => {
                    onReportSaveOpportunities();
                  }}
                  style={{ backgroundColor: isOpportunitiesChecked ? currentTheme?.primary : '#FFFFFF' }}
                  type="button"
                  className={cn('w-full h-full rounded-full')}
                >
                </button>
              </div>
            </div>
            )}
          </div>
          {overviewData?.opportunities?.length !== 0
            ? <DataWithSteps data={overviewData?.opportunities} isLoading={isPendingReRun || isOverviewLoading} />
            : <p className="w-full text-left text-[15px] max-w-[1300px] mx-auto">{translateNoOpportunitiesData[analysisLanguage] || translateNoOpportunitiesData.English}</p>}
        </div>
      </div>
    ) : (
      <div className="flex flex-col items-center justify-center w-full h-full min-h-[65vh] text-center">
        {singleProjectData?.status !== EProjectStatus.COMPLETED && (
          <Button
            variant="default"
            disabled={isPendingReRun}
            className="relative flex items-center gap-2 mt-5 font-bold px-7"
            onClick={onReRun}
          >
            {
            isPendingReRun
              ? <IconLoader2 className="animate-spin" size={20} />
              : <PaperAirplaneIcon className="size-4" />
          }
            {isPendingReRun ? 'Running...' : 'Run'}
            {newConversationsCount > 0 && (
            <span className="absolute flex items-center justify-center h-5 px-1.5 text-xs text-white bg-red-600 rounded-full w-fit -top-1 -right-1.5">
              {newConversationsCount}
            </span>
            )}
          </Button>
        )}
      </div>
    )
  );
}

export default Overview;
