import { useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { IconLoader2 } from '@tabler/icons-react';
import useTheme from 'chat-ui/src/hooks/useTheme';
import TranscriptsTable from './transcripts_table_data/TranscriptsTable';
import SingleTranscriptData from './SingleTranscriptData';
import useResultTabStore from '@/store/ResultsTabStore';
import axiosInstanceAnalyze from '@/axios/AxiosInstanceAnalyze';

function ConversationsTranscripts() {
  const { project_id } = useParams();
  const [searchParams] = useSearchParams();
  const { currentTheme } = useTheme();
  const selected_transcript = searchParams.get('selected_transcript');
  const filter_id = searchParams.get('filter_id');
  const activeTab = useResultTabStore((state) => state.activeTab);

  const { data: allConversations, isLoading, isFetching } = useQuery<AllTranscriptsProps[]>({
    queryFn: () => axiosInstanceAnalyze.get(`/analyzer/results/${project_id}/get_conversations${filter_id ? `?filter_id=${filter_id}` : ''}`).then((response) => response.data),
    queryKey: ['single_conversations_data', { filter_id, project_id }],
    select: (data) => data,
    enabled: activeTab === 'transcripts_tab',
  });

  const transcriptNumber = Number(selected_transcript);
  const selectedTranscript = allConversations?.[transcriptNumber - 1];
  const allConversationsLength = allConversations?.length;

  if ((isLoading || isFetching) && !allConversations) {
    return (
      <div className="flex items-center mx-auto max-w-[1300px] justify-center w-full h-full p-40">
        <IconLoader2 style={{ color: currentTheme?.primary }} className="animate-spin" size={30} />
      </div>
    );
  }

  return (
    <div className="w-full mx-auto max-w-[1300px]">
      {
        !selected_transcript
          ? <TranscriptsTable allConversations={allConversations} project_id={project_id} />
          : (
            <SingleTranscriptData
              selectedTranscript={selectedTranscript}
              transcriptNumber={transcriptNumber}
              allConversationsLength={allConversationsLength}
              selected_transcript={selected_transcript}
            />
          )
      }
    </div>
  );
}

export default ConversationsTranscripts;
