import { IconSquareRoundedNumber1Filled, IconSquareRoundedNumber2Filled, IconSquareRoundedNumber3Filled } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import useTheme from 'chat-ui/src/hooks/useTheme';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import useChatStore from 'chat-ui/src/store';
import axiosInstance from '@/axios/axiosInstance';
import DashboardTestChat from '@/components/chat/DashboardTestChat';
import Context from '@/components/projects/single-project/research-design/Context';
import Instructions from '@/components/projects/single-project/research-design/Instructions';
import Script from '@/components/projects/single-project/research-design/Script';
import { Skeleton } from '@/components/ui/skeleton';
import { LangContext } from '@/context/LangProvider';
import { EProjectStatus, EStatusCode } from '@/enums';
import useErrorStore from '@/store/ErrorStore';
import useQuestionsStore from '@/store/ScriptQuestionsStore';
import TestChatSkeletonLoading from './DashboardTestChatSkeletonLoading';

function ProjectCreate() {
  const { lang } = useContext(LangContext);
  const [isInstructionsOpen, setIsInstructionsOpen] = useState(true);
  const [isContextOpen, setIsContextOpen] = useState(false);
  const [isScriptOpen, setIsScriptOpen] = useState(false);
  const { workspace_id } = useParams();
  const questionsLength = useQuestionsStore((state) => state.questionsLength);
  const { currentTheme } = useTheme();
  const setShowError = useErrorStore((state) => state.setShowError);
  const { project_id } = useParams();
  const { pathname } = useLocation();
  const { setInitialMessages } = useChatStore();

  const { data: singleProjectData, error: projectDataError, refetch } = useQuery({
    queryFn: () => axiosInstance.get(`projects/get/${project_id}`),
    queryKey: ['single_project_data', { project_id }],
    select: (data) => data?.data,
    retry: 0,
  });

  const handleInstructionsClick = () => {
    setIsInstructionsOpen(!isInstructionsOpen);
    setIsContextOpen(false);
    setIsScriptOpen(false);
  };

  const handleContextClick = () => {
    setIsInstructionsOpen(false);
    setIsContextOpen(!isContextOpen);
    setIsScriptOpen(false);
  };

  const handleScriptClick = () => {
    setIsInstructionsOpen(false);
    setIsContextOpen(false);
    setIsScriptOpen(!isScriptOpen);
  };

  useEffect(() => {
    if ((projectDataError as AxiosError)?.status === EStatusCode.BAD_REQUEST
      || (projectDataError as AxiosError)?.status === EStatusCode.PROJECT_NOT_FOUND) {
      setShowError(true);
    }
  }, [projectDataError, setShowError]);

  useEffect(() => {
    const handlePageLeave = () => {
      setInitialMessages([]);
    };

    return () => {
      handlePageLeave();
    };
  }, [pathname]);

  return (
    <main className="flex w-full h-full -mt-2.5">
      <div className="flex flex-col w-1/2 gap-2">
        <h2 className="mt-3 text-xl font-semibold">{lang.get('msg.design')}</h2>
        {/* instructions */}
        <div className="flex flex-col w-full">
          {singleProjectData ? (
            <button
              type="button"
              className="flex items-center justify-start w-full gap-1.5 py-2 mb-4 border-b"
              onClick={handleInstructionsClick}
            >
              <IconSquareRoundedNumber1Filled
                style={{ color: currentTheme?.primary }}
                size={20}
              />
              {lang.get('msg.instructions')}
            </button>
          ) : (
            <div className="flex flex-col mt-2.5 mb-4">
              <div className="flex gap-1.5">
                <Skeleton className="w-5 h-5 rounded-sm" />
                <Skeleton className="h-5 rounded-sm w-28" />
              </div>
              <Skeleton className="w-full h-0.5 mt-2.5" />
            </div>
          )}
          <div
            className={`transition-all h-full ease-in-out duration-700 flex flex-col overflow-hidden -mt-1 ${isInstructionsOpen ? 'max-h-[1500px]' : 'max-h-0'
            }`}
          >
            <Instructions
              workspaceID={workspace_id}
              projectId={project_id}
              singleProjectData={singleProjectData}
              isProjectPublished={singleProjectData?.status === EProjectStatus.PUBLISHED}
              isProjectCompleted={singleProjectData?.status === EProjectStatus.COMPLETED}
            />
          </div>
        </div>

        {/* context */}
        <div className="flex flex-col w-full">
          <button
            type="button"
            className="flex items-center justify-start w-full gap-1.5 py-2 mb-4 border-b"
            onClick={handleContextClick}
          >
            <IconSquareRoundedNumber2Filled style={{ color: currentTheme?.primary }} size={20} />
            {lang.get('msg.context')}
          </button>
          <div
            className={`transition-all ease-in-out duration-700 flex flex-col overflow-hidden -mt-1 ${isContextOpen ? 'max-h-[800px]' : 'max-h-0'
            }`}
          >
            <Context
              workspaceID={workspace_id}
              projectId={project_id}
              singleProjectData={singleProjectData}
              isProjectPublished={singleProjectData?.status === EProjectStatus.PUBLISHED}
              isProjectCompleted={singleProjectData?.status === EProjectStatus.COMPLETED}
            />
          </div>
        </div>

        {/* script */}
        <div className="flex flex-col w-full">
          <button
            type="button"
            className="flex items-center justify-start w-full gap-1.5 py-2 mb-4 border-b"
            onClick={handleScriptClick}
          >
            <IconSquareRoundedNumber3Filled style={{ color: currentTheme?.primary }} size={20} />
            {lang.get('msg.script')}
          </button>
          <div
            style={{ maxHeight: isScriptOpen ? `${1000 + questionsLength * 200}px` : 0 }}
            className="flex flex-col -mt-1 overflow-hidden transition-all duration-1000 ease-in-out"
          >
            <Script
              workspaceID={workspace_id}
              projectId={project_id}
              singleProjectData={singleProjectData}
              isProjectPublished={singleProjectData?.status === EProjectStatus.PUBLISHED}
              isProjectCompleted={singleProjectData?.status === EProjectStatus.COMPLETED}
              refetch={refetch}
            />
          </div>
        </div>
      </div>

      {/* chat side */}
      <div className="fixed w-1/2 h-full right-4">
        <h2 className="mt-3 ml-8 text-xl font-semibold capitalize">{lang.get('msg.testChat')}</h2>
        <div
          style={{ backgroundColor: currentTheme?.background }}
          className="w-full h-screen pb-20 pl-6"
        >
          {singleProjectData ? (
            <DashboardTestChat
              projectId={project_id}
              chatHeight="min-h-[calc(100dvh-190px)] max-h-[calc(100dvh-190px)]"
            />
          ) : (
            <TestChatSkeletonLoading />
          )}
        </div>
      </div>
    </main>
  );
}

export default ProjectCreate;
