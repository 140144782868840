/* eslint-disable @typescript-eslint/no-explicit-any */
import { IconAdjustmentsHorizontal, IconLoader2, IconDownload } from '@tabler/icons-react';
import {
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { ChevronDown } from 'lucide-react';
import { useMemo, useState } from 'react';
import { Button } from '@/components/ui/button';
import { DropdownMenu, DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import useTranslation from '@/hooks/useTranslation';
import { TranscriptsColumns } from './TranscriptsColumns';
import { toast } from '@/components/ui/use-toast';
import { useMutateDownloadAllConversationsAsCSV } from '@/reactQuery/post';
import getUniqueMetadataKeys from '@/utils/getUniqueMetadataKeys';

interface ITableData {
  allConversations?: AllTranscriptsProps[];
  project_id?: string;
}

export default function TranscriptsTable({ allConversations, project_id }: ITableData) {
  const { lang } = useTranslation();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});
  const { mutateAsync: mutateAsyncDownloadAllAsCSV, isPending: isDownloadingAllPending } = useMutateDownloadAllConversationsAsCSV();

  const uniqueMetadataKeys = useMemo(() => {
    if (allConversations) {
      return getUniqueMetadataKeys(allConversations);
    }
    return new Set<string>();
  }, [allConversations]);
  // Convert Set to Array
  const metadataKeysArray = Array.from(uniqueMetadataKeys);

  const downloadAsCSVAllConversations = ({ conversation_data }: any) => {
    let csvContent = '';

    csvContent += `${conversation_data[0].join(',')}\n`;

    conversation_data.slice(1).forEach((conversation: any) => {
      conversation.forEach((row: any[]) => {
        csvContent += `${row.join(',')}\n`;
      });
    });

    // Create a blob with the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    // Create a link element
    const link = document.createElement('a');

    // Create a URL for the blob and set it as the href attribute
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);

    // Set the download attribute with a file name
    link.setAttribute('download', 'conversations.csv');

    // Append the link to the document body
    document.body.appendChild(link);

    // Trigger the download by programmatically clicking the link
    link.click();

    // Clean up by removing the link and revoking the object URL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const downlaodAllConversationsAsCSV = async () => {
    try {
      const result = await mutateAsyncDownloadAllAsCSV({ project_id });
      const conversationData = result.data;
      downloadAsCSVAllConversations({ conversation_data: conversationData });
      toast({ description: 'All conversations successfully downloaded.' });
    } catch (err) {
      toast({ description: lang.get('msg.errorPleaseTryAgain'), variant: 'destructive' });
    }
  };

  const columns = TranscriptsColumns(metadataKeysArray);

  const table = useReactTable({
    data: allConversations || [],
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
    initialState: {
      pagination: {
        pageSize: 20,
      },
    },
  });

  if (!allConversations) return null;

  return (
    <div className="w-full">
      <div className="flex flex-wrap items-center w-full gap-4 pt-4 pb-2">
        <div className="flex items-center justify-end w-full gap-4 mb-2">
          <div className="flex items-center gap-2">
            <Button
              disabled={isDownloadingAllPending}
              variant="default"
              onClick={downlaodAllConversationsAsCSV}
              className="flex items-center gap-1"
            >
              {isDownloadingAllPending ? <IconLoader2 className="animate-spin" size={18} /> : <IconDownload size={18} />}
              {lang.get('msg.exportAll')}
            </Button>
            {/* column filters */}
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline" className="ml-auto">
                  <IconAdjustmentsHorizontal size={16} className="mr-2" />
                  View
                  <ChevronDown className="w-4 h-4 ml-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                {table
                  .getAllColumns()
                  .filter((column) => column.getCanHide())
                  .map((column) => (
                    <DropdownMenuCheckboxItem
                      key={column?.id}
                      className="capitalize"
                      checked={column.getIsVisible()}
                      onCheckedChange={(value) => column.toggleVisibility(!!value)}
                    >
                      {column?.id}
                    </DropdownMenuCheckboxItem>
                  ))}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </div>
      <div className="border rounded-md">
        <Table>
          <TableHeader>
            {table?.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup?.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header?.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table?.getRowModel().rows?.length ? (
              table?.getRowModel().rows.map((row) => (
                <TableRow key={row?.id} data-state={row.getIsSelected() && 'selected'}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell?.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns?.length} className="h-24 text-center">
                  {lang.get('msg.noResults')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end w-full py-4 space-x-2">
        <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => table?.previousPage()}
            disabled={!table?.getCanPreviousPage()}
          >
            {lang.get('msg.previous')}
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => table?.nextPage()}
            disabled={!table?.getCanNextPage()}
          >
            {lang.get('msg.next')}
          </Button>
        </div>
      </div>
    </div>
  );
}
