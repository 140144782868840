import { useQuery } from '@tanstack/react-query';
import {
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import axiosInstance from '@/axios/axiosInstance';
import axiosInstanceAnalyze from '@/axios/AxiosInstanceAnalyze';
import { Button } from '@/components/ui/button';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { LangContext } from '@/context/LangProvider';
import { EPanelIntegration } from '@/enums';
import { QuestionAnswersColumns } from './QuestionAnswersColumns';

interface ITableProps {
  selectedQuestionTranscripts: ISelectedQuestion[] | undefined;
  questionID: string | undefined;
}

interface ICategory {
  label: string;
  color: string;
  prompt: string;
}

interface ISelectedQuestion {
  gender?: string;
  age?: number;
  started: string;
  conversation_id: string;
  questions: string;
  category: ICategory
}

export default function QuestionAnswersTable({ selectedQuestionTranscripts, questionID }: ITableProps) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = useState({});
  const { lang } = useContext(LangContext);
  const { project_id, workspace_id } = useParams();
  const [searchParams] = useSearchParams();
  const filter_id = searchParams.get('filter_id');
  const [areAllCategoriesZero, setAreAllCategoriesZero] = useState(false);

  const { data: singleProjectData } = useQuery({
    queryFn: () => axiosInstance.get(`projects/get/${project_id}`),
    queryKey: ['single_project_data', { project_id }],
    select: (data) => data?.data,
  });

  const { data: categoryData } = useQuery({
    queryFn: () => {
      const baseUrl = `/analyzer/quantification/${project_id}/${questionID}`;
      const url = filter_id ? `${baseUrl}?filter_id=${filter_id}` : baseUrl;
      return axiosInstanceAnalyze.get(url);
    },
    queryKey: ['category_data', { questionID, filter_id, project_id }],
    enabled: !!project_id && !!questionID,
    select: (category_data) => category_data?.data,
  });

  const areAllCountsZeroCheck = useCallback(() => {
    // Check if all counts in 'categories' are 0
    const allCategoriesZero = categoryData?.categories.every(
      (category: { count: number }) => category.count === 0,
    );

    // Check if all counts in 'categories_multi' are 0
    const allCategoriesMultiZero = categoryData?.categories_multi.every(
      (category: { count: number }) => category.count === 0,
    );

    // Set state based on whether all counts are zero
    setAreAllCategoriesZero(allCategoriesZero && allCategoriesMultiZero);
  }, [categoryData, setAreAllCategoriesZero]);

  const isCategoryDataExists = categoryData?.categories?.length !== 0 || categoryData?.categories_multi?.length !== 0;

  const categoryDataSingle = categoryData?.categories;
  const categoryDataMultiple = categoryData?.categories_multi;

  const isPanel = singleProjectData?.panel_integration !== EPanelIntegration.NONE;

  const columns = QuestionAnswersColumns(
    isPanel,
    categoryDataSingle,
    questionID,
    categoryDataMultiple,
    isCategoryDataExists,
    areAllCategoriesZero,
    project_id,
    workspace_id,
  );

  const table = useReactTable({
    data: selectedQuestionTranscripts || [],
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
    initialState: {
      pagination: {
        pageSize: 15,
      },
    },
  });

  useEffect(() => {
    areAllCountsZeroCheck();
  }, [categoryData, areAllCountsZeroCheck]);

  return (
    <div className="w-full mt-4">
      <div className="border rounded-md">
        <Table className="text-dark-text">
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table?.getRowModel()?.rows?.length ? (
              table?.getRowModel()?.rows?.map((row) => (
                <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns?.length} className="h-24 text-center">
                  {lang.get('msg.noResults')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end py-4 space-x-2">
        {table.getFilteredRowModel().rows.length >= 15
          && (
            <div className="space-x-2">
              <Button
                variant="outline"
                size="sm"
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
              >
                {lang.get('msg.previous')}
              </Button>
              <Button
                variant="outline"
                size="sm"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              >
                {lang.get('msg.next')}
              </Button>
            </div>
          )}
      </div>
    </div>
  );
}
