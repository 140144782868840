import { IconChevronLeft, IconChevronRight, IconDownload, IconLoader2 } from '@tabler/icons-react';
import { useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import ConversationMessage from '@/components/chat/ConversationMessage';
import formatDate from '@/utils/dateFormat';
import { Button } from '@/components/ui/button';
import { useMutateDownloadConversationAsCSV } from '@/reactQuery/post';
import { toast } from '@/components/ui/use-toast';
import useTranslation from '@/hooks/useTranslation';
import truncateText from '@/utils/truncateText';
import calculateConversationDuration from '@/utils/calculateConversationDuration';

interface ITranscriptProps {
  selectedTranscript?: AllTranscriptsProps;
  transcriptNumber: number;
  allConversationsLength?: number;
  selected_transcript: string;
}

const MAX_SUMMARY = 80;

function SingleTranscriptData({ selectedTranscript, transcriptNumber, allConversationsLength, selected_transcript }: ITranscriptProps) {
  const { lang } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { mutateAsync: mutateAsyncDownloadAsCSV, isPending: isDownloadPending } = useMutateDownloadConversationAsCSV();
  const [isExpanded, setIsExpanded] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const downloadCSV = ({ conversation_data, id }: { conversation_data: any, id: string }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const csvContent = conversation_data.map((row: any) => row.join('\t')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `tellet_conversation_${id}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downlaodConversationAsCSV = async () => {
    try {
      const result = await mutateAsyncDownloadAsCSV({ conversation_id: selectedTranscript?._id as string });
      const conversationData = result.data;
      downloadCSV({ conversation_data: conversationData, id: selectedTranscript?._id as string });
      toast({ description: 'Conversation successfully downloaded.' });
    } catch (err) {
      toast({ description: lang.get('msg.errorPleaseTryAgain'), variant: 'destructive' });
    }
  };

  const removeSelectedTranscriptAndGoBack = () => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.delete('selected_transcript');
    setSearchParams(newSearchParams);
  };

  const addQueryParams = (params: Record<string, string>) => {
    Object.entries(params).forEach(([key, value]) => {
      searchParams.set(key, value);
    });
    setSearchParams(searchParams);
  };

  const handleNextTranscript = () => {
    const nextNumber = Number(transcriptNumber) + 1;
    addQueryParams({ selected_transcript: String(nextNumber) });
  };

  const handlePrevTranscript = () => {
    const prevNumber = Number(transcriptNumber) - 1;
    addQueryParams({ selected_transcript: String(prevNumber) });
  };

  return (
    <div className="flex flex-col w-full">
      <div className="flex items-center justify-between w-full mb-4">
        <button onClick={removeSelectedTranscriptAndGoBack} type="button" className="hover:font-semibold flex items-center gap-1.5">
          <IconChevronLeft size={18} className="mt-0.5" />
          Go back
        </button>
        <div className="flex items-center gap-4">
          <div className="flex items-center bg-white rounded-lg text-dark-text">
            <button
              disabled={Number(selected_transcript) === 1}
              type="button"
              className="flex items-center justify-center border border-black rounded-l-lg disabled:cursor-not-allowed h-9 w-9"
              onClick={handlePrevTranscript}
            >
              <IconChevronLeft size={18} />
            </button>
            <div className="flex items-center justify-center px-3 text-sm font-semibold border-t border-b border-black h-9 w-fit">
              Participant
              {' '}
              {Number(selected_transcript)}
            </div>
            <button
              disabled={Number(selected_transcript) >= Number(allConversationsLength)}
              type="button"
              className="flex items-center justify-center border border-black rounded-r-lg disabled:cursor-not-allowed h-9 w-9"
              onClick={handleNextTranscript}
            >
              <IconChevronRight size={18} />
            </button>
          </div>
          <Button
            disabled={isDownloadPending}
            variant="default"
            onClick={downlaodConversationAsCSV}
            className="flex items-center gap-1 text-sm"
          >
            {isDownloadPending ? <IconLoader2 className="animate-spin" size={18} /> : <IconDownload size={18} />}
            {lang.get('msg.export')}
          </Button>
        </div>
      </div>
      <div className="flex w-full gap-5 md800:flex-col-reverse">
        <div className="relative flex w-full overflow-hidden border rounded-tr-lg rounded-br-lg shadow-sm border-dark-text/10">
          {/* chat */}
          <div className="flex flex-col w-full h-full p-3 max-h-[600px] bg-secondary-background">
            <div className="relative flex flex-col w-full h-full px-2 overflow-hidden overflow-y-auto">
              <div className="flex flex-col w-full gap-4 pb-2 mt-8">
                {selectedTranscript?.messages?.map((item) => (
                  <ConversationMessage key={item?._id} {...item} />
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* info data */}
        <div className="w-[500px] text-dark-text max-h-[600px] overflow-y-auto md800:w-full h-full bg-secondary-background shadow-sm border border-dark-text/10 rounded-lg p-4">
          <div className="flex flex-col gap-3">
            <ul className="flex flex-col text-sm gap-0.5">
              <li className="font-bold">Conversation ID:</li>
              <li className="font-normal">{selectedTranscript?._id}</li>
            </ul>
            <ul className="flex flex-col text-sm gap-0.5">
              <li className="font-bold">Created at:</li>
              <li className="font-normal">{formatDate(selectedTranscript?.createdAt as string, 'DD/MMM/YYYY - hh:mm:ss A')}</li>
            </ul>
            <ul className="flex flex-col text-sm gap-0.5">
              <li className="font-bold">Status:</li>
              <li className="font-normal capitalize">{selectedTranscript?.status}</li>
            </ul>
            <ul className="flex flex-col text-sm gap-0.5">
              <li className="font-bold">Completion time:</li>
              <li className="font-normal">
                {calculateConversationDuration({ createdAt: selectedTranscript?.createdAt as string,
                  updatedAt: selectedTranscript?.updatedAt as string })}
              </li>
            </ul>
            <ul className="flex flex-col text-sm gap-0.5">
              <li className="font-bold">Metadata:</li>
              <li className="font-normal capitalize">
                {Object?.entries(!!selectedTranscript?._id && selectedTranscript?.metadata)?.map(([key, value]) => (
                  <div className="flex font-medium whitespace-nowrap" key={key}>
                    <p className="capitalize">{key}</p>
                    :
                    <p className="ml-1">
                      {String(value)}
                    </p>
                  </div>
                ))}
              </li>
            </ul>
            <ul className="flex flex-col text-sm gap-0.5">
              <li className="font-bold">Total messages:</li>
              <li className="font-normal capitalize">{selectedTranscript?.messages.length}</li>
            </ul>
            {selectedTranscript?.digest?.summary && (
              <ul className="flex flex-col text-sm gap-0.5">
                <li className="font-bold">Conversation summary:</li>
                <li className="font-normal">
                  {isExpanded ? selectedTranscript?.digest?.summary : truncateText(selectedTranscript?.digest?.summary as string, MAX_SUMMARY)}
                  {selectedTranscript?.digest?.summary?.split(' ').length > MAX_SUMMARY && (
                    <button
                      type="button"
                      className="ml-1 text-blue-500 hover:underline"
                      onClick={() => setIsExpanded(!isExpanded)}
                    >
                      {isExpanded ? 'Show less' : 'Show more'}
                    </button>
                  )}
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleTranscriptData;
