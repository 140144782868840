import { NavLink, useParams } from 'react-router-dom';
import { useContext, useState } from 'react';
import useTheme from 'chat-ui/src/hooks/useTheme';
import { Button } from '../ui/button';
import { LangContext } from '@/context/LangProvider';
import Routes from '@/router/routes';
import ChevronRightIcon from '../icons/ChevronRightIcon';
import UsersIcon from '../icons/UsersIcon';

interface IDropDown {
  hideSidebar: boolean;
  isSharedOpen: boolean;
  setIsSharedOpen: React.Dispatch<React.SetStateAction<boolean>>;
  closeSidebarOnClick: () => void;
  workspaces?: IWorkspace[];
  setHideSidebar: (value: boolean) => void;
  setIsPrivateOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function SharedDropDown(
  { hideSidebar, isSharedOpen, setIsSharedOpen,
    closeSidebarOnClick, workspaces, setHideSidebar, setIsPrivateOpen }
  : IDropDown,
) {
  const { lang } = useContext(LangContext);
  const { workspace_id } = useParams();
  const { currentTheme } = useTheme();
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredItems, setHoveredItems] = useState<Record<string, boolean>>({});

  const handleMouseEnter = (id: string) => {
    setHoveredItems((prev) => ({ ...prev, [id]: true }));
  };

  const handleMouseLeave = (id: string) => {
    setHoveredItems((prev) => ({ ...prev, [id]: false }));
  };

  return (
    <div>
      {!hideSidebar ? (
        <li
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={{ backgroundColor: isHovered ? currentTheme?.['lighter-primary'] : currentTheme?.background, color: currentTheme?.['primary-text'] }}
          className={`text-sm items-center pl-3 whitespace-nowrap rounded-md h-11
               bg-white text-dark-text transition-all ease-in-out
               ${hideSidebar ? '!pl-0 max-w-[55px] w-full' : ''} `}
        >
          <button
            onClick={() => { setIsSharedOpen(!isSharedOpen); setIsPrivateOpen(false); }}
            type="button"
            className={`flex items-center w-full h-full ${hideSidebar ? 'justify-center min-w-[55px]' : ''
            }`}
          >
            <ChevronRightIcon className={`min-w-[18px] max-w-[18px] transition-all ease-in-out min-h-[18px] mr-1.5 ${hideSidebar ? 'hidden' : 'flex'} ${isSharedOpen && 'rotate-90'}`} />
            <div className="flex items-center gap-1">
              <UsersIcon className="min-w-[21px] min-h-[21px]" />
              <p className={`${hideSidebar ? 'hidden' : 'flex'} font-medium`}>{lang.get('msg.shared')}</p>
            </div>
          </button>
        </li>
      ) : (
        <Button
          variant="ghost"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={{ backgroundColor: isHovered ? currentTheme?.['lighter-primary'] : currentTheme?.background, color: currentTheme?.['primary-text'] }}
          onClick={() => {
            setHideSidebar(!hideSidebar);
            setIsSharedOpen(true);
            setIsPrivateOpen(false);
          }}
          className="w-[38px] transition-all ease-in-out h-[38px] border-none rounded-md min-w-[55px] outline-none select-none ring-offset-transparent ring-transparent focus-visible:ring-0 focus-visible:shadow-none focus-visible:ring-opacity-0"
        >
          <UsersIcon className="min-w-[21px] min-h-[21px]" />
        </Button>
      )}

      {/* shared links */}
      <ul className={`overflow-hidden overflow-y-auto gap-1 flex flex-col transition-all duration-500 ${isSharedOpen ? 'max-h-[calc(100vh-380px)]' : 'max-h-0'}
       ${hideSidebar && 'hidden'}`}
      >
        {workspaces?.map((item) => (
          <NavLink
            key={item._id}
            to={`${Routes.organization.path}/${item.organization_id}${Routes.workspace.path}/${item._id}`}
            onClick={() => {
              closeSidebarOnClick();
            }}
            rel="preload"
            className={`flex items-center w-full h-full justify-center ${hideSidebar ? 'justify-center' : ''
            }`}
          >
            <li
              onMouseEnter={() => handleMouseEnter(item._id)}
              onMouseLeave={() => handleMouseLeave(item._id)}
              style={{
                // eslint-disable-next-line no-nested-ternary
                backgroundColor: workspace_id === item._id
                  ? currentTheme?.primary
                  : hoveredItems[item._id]
                    ? currentTheme?.['lighter-primary']
                    : currentTheme?.background,
                color: workspace_id === item._id ? '#FFFFFF' : currentTheme?.['primary-text'],
              }}
              className={`text-sm first:mt-1 w-full whitespace-nowrap gap-3 flex items-center pl-3 rounded-md min-h-[44px] ${workspace_id !== item._id
                && 'bg-white text-dark-text whitespace-nowrap transition-all ease-in-out'
              } ${hideSidebar ? '!pl-0 max-w-[55px] w-full' : ''}`}
            >
              <p className={`w-full h-full flex items-center justify-between ${hideSidebar ? 'hidden' : 'flex'}`}>
                <span>{item.name}</span>
                <span className="mr-3">{item.projectCount}</span>
              </p>
            </li>
          </NavLink>
        ))}
      </ul>
    </div>
  );
}

export default SharedDropDown;
