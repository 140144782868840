import useTheme from 'chat-ui/src/hooks/useTheme';
import { Outlet, useLocation } from 'react-router-dom';
import Navbar from 'src/components/Navbar/Navbar';
import Sidebar from 'src/components/Sidebar/Sidebar';
import useSidebarStore from '@/store/SidebarStore';
import Routes from '@/router/routes';
import { cn } from '@/lib/utils';

function AuthContent() {
  const location = useLocation();
  const isLocationProjectPage = location.pathname.includes(Routes.project.path);
  const hideSidebar = useSidebarStore((state) => state.hideSidebar);
  const { currentTheme } = useTheme();

  return (
    <div className="flex w-full">
      {!isLocationProjectPage && <Sidebar />}
      <main
        style={{ backgroundColor: currentTheme?.background, color: currentTheme?.['primary-text'] }}
        className="relative w-full h-full min-h-screen p-5 pt-0 overflow-hidden transition-all ease-in text-dark-text"
      >
        <div className={`flex flex-col w-full transition-all ease-in h-full min450:pl-0 
        ${isLocationProjectPage && '!pl-0'}
        ${(!hideSidebar && !isLocationProjectPage) ? 'pl-[270px]' : 'pl-20'}`}
        >
          <Navbar />
          <div className={cn(isLocationProjectPage ? 'pt-16' : 'pt-20')}>
            <Outlet />
          </div>
        </div>
      </main>
    </div>
  );
}

export default AuthContent;
